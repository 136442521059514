import { put, call, fork, take, select } from 'redux-saga/effects';
import types from './types';
import actions from './actions';
import { galleryItemsActions } from '../galleryItems';
import { GetGalleryImages } from '../services/webAppEndPoint';
import Config from '../config';

const pageSize = 48;

function* putImages(images) {
  yield put(galleryItemsActions.addItems(images));
}

function* getGallery({ id }) {
  try {
    const { images: profileImages } = yield call(GetGalleryImages, id, null, 10, true);
    const { images, moreRecords } = yield call(GetGalleryImages, id, null, pageSize);
    yield fork(putImages, [...profileImages, ...images]);
    yield put(actions.getGallerySuccess(id, moreRecords));
  } catch (error) {
    yield put(actions.getGalleryFailure(id, error));
  }
}

function* handleGetGallery() {
  let startedIds = [];
  while (true) {
    const action = yield take([types.getGallery, types.getGallerySuccess, types.getGalleryFailure]);
    const { type, id } = action;
    if (type !== types.getGallery) {
      startedIds = startedIds.filter((item) => item !== id);
    } else if (startedIds.every((item) => item !== id)) {
      yield fork(getGallery, action);
      startedIds = [...startedIds, id];
    }
  }
}

function* loadMore({ id }) {
  const { nextPage, moreRecords: oldMoreRecords } = yield select(
    ({ galleries }) => galleries.byId[id]
  );
  if (oldMoreRecords) {
    try {
      const { moreRecords, images } = yield call(GetGalleryImages, id, nextPage, pageSize);
      yield fork(putImages, images);
      yield put(actions.loadMoreSuccess(id, moreRecords));
    } catch (error) {
      yield put(actions.loadMoreFailure(id, error));
    }
  }
}

function* handleLoadMore() {
  let startedIds = [];
  while (true) {
    const action = yield take([types.loadMore, types.loadMoreSuccess, types.loadMoreFailure]);
    const { type, id } = action;
    if (type !== types.loadMore) {
      startedIds = startedIds.filter((item) => item !== id);
    } else if (startedIds.every((item) => item !== id)) {
      yield fork(loadMore, action);
      startedIds = [...startedIds, id];
    }
  }
}

function* removeGallery(id) {
  yield put(galleryItemsActions.removeGallery(id));
  yield put(actions.remove(id));
}

function* galleryCache() {
  const cache = [];
  while (true) {
    const { id: newestId } = yield take(types.getGallerySuccess);
    cache.push(newestId);
    if (cache.length > Config.cacheAmount) {
      const id = cache.shift();
      yield fork(removeGallery, id);
    }
  }
}

export default {
  galleryCache,
  handleGetGallery,
  handleLoadMore,
};
