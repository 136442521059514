import { uniq, omit } from 'lodash';
import types from './types';

const reducer = (state = { allIds: [], byId: {} }, action) => {
  const { type } = action;
  switch (type) {
    case types.getGallery:
    case types.getGallerySuccess:
    case types.getGalleryFailure:
    case types.loadMore:
    case types.loadMoreFailure:
    case types.loadMoreSuccess: {
      const { id } = action;
      const { allIds, byId } = state;
      return {
        allIds: uniq([...allIds, id]),
        byId: { ...byId, [id]: galleryReducer(byId[id], action) },
      };
    }
    case types.removeGallery: {
      const { id } = action;
      const { allIds, byId } = state;
      return { allIds: allIds.filter(item => item !== id), byId: omit(byId, id) };
    }
    default:
      return state;
  }
};

const galleryReducer = (state = { isLoading: true }, action) => {
  const { type } = action;
  switch (type) {
    case types.getGallerySuccess: {
      const { moreRecords } = action;
      return { isLoading: false, moreRecords, nextPage: 2 };
    }
    case types.getGalleryFailure: {
      const { error } = action;
      return { isLoading: false, error };
    }
    case types.loadMore: {
      return { ...state, isLoading: true };
    }
    case types.loadMoreSuccess: {
      const { moreRecords } = action;
      return {
        ...state,
        isLoading: false,
        moreRecords,
        nextPage: state.nextPage + 1,
      };
    }
    case types.loadMoreFailure: {
      const { error } = action;
      return {
        ...state,
        isLoading: false,
        error,
      };
    }
    default:
      return state;
  }
};

export default reducer;
