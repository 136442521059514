import types from './types';

const getGallery = id => ({ type: types.getGallery, id });
const getGallerySuccess = (id, moreRecords) => ({ type: types.getGallerySuccess, id, moreRecords });
const getGalleryFailure = (id, error) => ({ type: types.getGalleryFailure, error });
const loadMore = id => ({ type: types.loadMore, id });
const loadMoreSuccess = (id, moreRecords) => ({ type: types.loadMoreSuccess, id, moreRecords });
const loadMoreFailure = (id, error) => ({ type: types.loadMoreFailure, id, error });
const remove = id => ({ type: types.removeGallery, id });

export default {
  getGallery,
  getGallerySuccess,
  getGalleryFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  remove,
};
