const getGallery = 'app/galleries/GET';
const getGallerySuccess = 'app/galleries/GETSUCCESS';
const getGalleryFailure = 'app/galleries/GETFAILURE';
const loadMore = 'app/galleries/LOADMORE';
const loadMoreSuccess = 'app/galleries/LOADMORESUCCESS';
const loadMoreFailure = 'app/galleries/LOADMOREFAILURE';
const removeGallery = 'app/galleries/REMOVE';

export default {
  getGallery,
  getGallerySuccess,
  getGalleryFailure,
  loadMore,
  loadMoreSuccess,
  loadMoreFailure,
  removeGallery,
};
